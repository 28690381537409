<template lang="pug">
  PageForm(:data="data")
</template>

<script>
import PageForm from './PageForm'

export default {
  name: 'PageEdit',
  components: {
    PageForm
  },
  data () {
    return {
      data: {
        id: undefined
      }
    }
  },
  computed: {},
  methods: {
    async getDetail() {
      if (!this.$route.params.id) return;

      const result = await this.$http.get(`page/${this.$route.params.id}`);
      this.data = result;
    },

  },
  created() {
    this.getDetail();
  }
}
</script>

<style lang="less" scoped></style>